export const RESET_CACHE = '@cache/reset-cache';
export const UPDATE_CACHE = '@cache/update-cache';

export function resetCache() {
  return async (dispatch) => {
    dispatch({
      type: RESET_CACHE,
    });
  };
}

export function updateCache(cache) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CACHE,
      payload: {
        cache
      }
    });
  };
}
