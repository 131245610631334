export const SEARCH_QUERY_REDUX = 'SEARCH_QUERY_REDUX';

export function searchQueryRedux(data) {
  return async (dispatch) => {
    dispatch({
      type: SEARCH_QUERY_REDUX,
      payload: {
        data
      }
    });
  };
}
