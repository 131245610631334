export const SET_STORE_ORDER_TYPE = 'SET_STORE_ORDER_TYPE';
export const SET_STORE_ORDER_ORIGIN_STORE = 'SET_STORE_ORDER_ORIGIN_STORE';
export const SET_STORE_ORDER_DESTINATION_STORE = 'SET_STORE_ORDER_DESTINATION_STORE';

export function setStoreOrderType(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_STORE_ORDER_TYPE,
      payload: {
        data
      }
    });
  };
}

export function setStoreOrderOriginStore(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_STORE_ORDER_ORIGIN_STORE,
      payload: {
        data
      }
    });
  };
}

export function setStoreOrderDestinationStore(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_STORE_ORDER_DESTINATION_STORE,
      payload: {
        data
      }
    });
  };
}
