/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SEARCH_QUERY_REDUX
} from 'src/actions/searchActions';

const initialState = {
  searchQuery: ''
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_QUERY_REDUX: {
      return produce(state, (draft) => {
        draft.searchQuery = action.payload.data;
      });
    }

    default: {
      return state;
    }
  }
};

export default searchReducer;
