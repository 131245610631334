const removeAccents = (str) => {
  if (!str) return '';
  if (typeof str !== 'string') return '';
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const lowerCaseNoAccents = (str) => removeAccents(str).toLocaleLowerCase();

module.exports = {
  removeAccents,
  lowerCaseNoAccents,
};
