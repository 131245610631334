export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_SUPPLYTYPE = 'SET_SUPPLYTYPE';
export const SET_PRODUCT_CLASSES = 'SET_PRODUCT_CLASSES';
export const SET_STORES = 'SET_STORES';

export function setSuppliers(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_SUPPLIERS,
      payload: {
        data
      }
    });
  };
}

export function setSupplyType(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_SUPPLYTYPE,
      payload: {
        data
      }
    });
  };
}

export function setProductClasses(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_PRODUCT_CLASSES,
      payload: {
        data
      }
    });
  };
}

export function setStores(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_STORES,
      payload: {
        data
      }
    });
  };
}
