/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_STORE_ORDER_TYPE,
  SET_STORE_ORDER_ORIGIN_STORE,
  SET_STORE_ORDER_DESTINATION_STORE,
} from 'src/actions/storeOrderActions';

const initialState = {
  storeOrderType: '',
  originStore: {},
  destinationStore: [],
};

const storeOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_ORDER_TYPE: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.storeOrderType = data;
      });
    }

    case SET_STORE_ORDER_ORIGIN_STORE: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.originStore = data;
      });
    }

    case SET_STORE_ORDER_DESTINATION_STORE: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.destinationStore = data;
      });
    }

    default: {
      return state;
    }
  }
};

export default storeOrderReducer;
