import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import searchReducer from './searchReducer';
import searchCacheReducer from './searchCacheReducer';
import purchaseOrderReducer from './purchaseOrderReducer';
import storeOrderReducer from './storeOrderReducer';
import cartReducer from './cartReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  search: searchReducer,
  searchCache: searchCacheReducer,
  purchaseOrder: purchaseOrderReducer,
  storeOrder: storeOrderReducer,
  form: formReducer,
  customer: cartReducer
});

export default rootReducer;
