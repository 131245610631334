import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ROLE_ADMIN,
  ROLE_VENDOR,
  ROLE_ADMIN_FRANCHISE,
  ROLE_VENDOR_FRANCHISE
} from 'src/utils/const';

function AuthGuard({ children }) {
  const account = useSelector((state) => state.account);

  const validRoles = [
    ROLE_ADMIN,
    ROLE_VENDOR,
    ROLE_ADMIN_FRANCHISE,
    ROLE_VENDOR_FRANCHISE,
  ];

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  if (!validRoles.includes(account.user.role)) {
    return <Redirect to="/login" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
