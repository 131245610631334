/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_SUPPLIERS,
  SET_SUPPLYTYPE,
  SET_PRODUCT_CLASSES,
  SET_STORES,
} from 'src/actions/purchaseOrderActions';

const initialState = {
  suppliers: [],
  supplyType: '',
  productClasses: [],
  stores: [],
};

const purchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUPPLIERS: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.suppliers = data;
      });
    }

    case SET_SUPPLYTYPE: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.supplyType = data;
      });
    }

    case SET_PRODUCT_CLASSES: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.productClasses = data;
      });
    }

    case SET_STORES: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        draft.stores = data;
      });
    }

    default: {
      return state;
    }
  }
};

export default purchaseOrderReducer;
