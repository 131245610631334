/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';

// Material UI Icons
import {
  Panorama as PanoramaIcon,
  Receipt,
} from '@material-ui/icons';
import {
  RequestQuote,
} from '@mui/icons-material';

import CodeIcon from '@mui/icons-material/Code';
// React Feather Icons
import {
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  File as FileIcon,
  BarChart as BarChartIcon,
  User as UserIcon,
  Users as UsersIcon,
  BookOpen as BookOpenIcon,
  Home as HomeIcon,
  Tag as TagIcon,
  Smartphone as SmartphoneIcon,
  Settings as SettingsIcon,
  DollarSign,
  Star as StarIcon,
  Columns as ColumnsIcon,
  Gift as GiftIcon,
  Percent as PercentIcon,
  Image as ImageIcon,
  Layout as LayoutIcon,
  Bookmark as BlogIcon,
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  Package as PackageIcon,
  Zap as ZapIcon,
  FilePlus as FilePlusIcon,
  MapPin as MapPinIcon,
  Activity as ActivityIcon,
  Paperclip as PaperclipIcon,
  CreditCard as CreditCardIcon,
  FileText as FileTextIcon,
  TrendingUp as TrendingUpIcon,
  List as ListIcon,
} from 'react-feather';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import StyleIcon from '@mui/icons-material/Style';

import Logo from 'src/components/Logo';
import {
  ROLE_ADMIN_FRANCHISE,
  ROLE_VENDOR,
  ROLE_VENDOR_FRANCHISE,
} from 'src/utils/const';
import NavItem from './NavItem';

const adminNavConfig = [
  {
    subheader: 'Analíticas',
    items: [
      {
        title: 'Socios Club Líquidos',
        icon: UsersIcon,
        href: '/app/reportes/socios',
      },
      {
        title: 'Ventas',
        icon: BarChartIcon,
        href: '/app/reportes/ventas',
      },
      {
        title: 'Rankings',
        icon: StarIcon,
        href: '/app/reportes/rankings',
      },
    ]
  },
  {
    subheader: 'Administrativo',
    items: [
      {
        title: 'Administradores',
        icon: UserIcon,
        href: '/app/admin/admins'
      },
      {
        title: 'Clientes',
        icon: UsersIcon,
        href: '/app/admin/clientes'
      },
      {
        title: 'Locales',
        icon: HomeIcon,
        href: '/app/admin/locales'
      },
      {
        title: 'Movimientos de Stock',
        icon: ActivityIcon,
        href: '/app/admin/movimientos-de-stock'
      },
      {
        title: 'Proveedores',
        icon: PackageIcon,
        href: '/app/admin/proveedores'
      },
      {
        title: 'Productos',
        icon: ShoppingCartIcon,
        href: '/app/admin/productos'
      },
      {
        title: 'Listas de Precios',
        icon: DollarSign,
        href: '/app/admin/listas-de-precios'
      },
      {
        title: 'Listas de Costos',
        icon: CreditCardIcon,
        href: '/app/admin/listas-de-costos'
      },
      {
        title: 'Listas de Precio Franquicias',
        icon: TrendingUpIcon,
        href: '/app/admin/listas-precios-franquicias'
      },
      {
        title: 'Administración de productos para compra',
        icon: FilePlusIcon,
        href: '/app/admin/productos-ordenes-de-compra'
      },
      {
        title: 'Órdenes de Compra',
        icon: ShoppingBagIcon,
        href: '/app/admin/ordenes-de-compra'
      },
      {
        title: 'Facturas de Compra',
        icon: PaperclipIcon,
        href: '/app/admin/facturas-de-compra'
      },
      {
        title: 'Notas de Crédito Proveedores',
        icon: FileTextIcon,
        href: '/app/admin/notas-de-credito-proveedores'
      },
      {
        title: 'Pedidos de Tienda',
        icon: TruckIcon,
        href: '/app/admin/pedidos-de-tienda'
      },
      {
        title: 'Pedidos',
        icon: FileIcon,
        href: '/app/admin/pedidos'
      },
      {
        title: 'Categorías',
        icon: FolderIcon,
        href: '/app/admin/categorias'
      },
      {
        title: 'Suscripciones',
        icon: BookOpenIcon,
        href: '/app/admin/suscripciones'
      },
      {
        title: 'Cupones',
        icon: TagIcon,
        href: '/app/admin/cupones'
      },
      {
        title: 'Promociones',
        icon: GiftIcon,
        href: '/app/admin/promociones'
      },
      {
        title: 'Promociones PEYA',
        icon: GiftIcon,
        href: '/app/admin/promociones-peya'
      },
      {
        title: 'Liquidazos',
        icon: PercentIcon,
        href: '/app/admin/liquidazos'
      },
      {
        title: 'Maquinas Redelcom',
        icon: SmartphoneIcon,
        href: '/app/admin/maquinas-redelcom'
      },
      {
        title: 'Configuraciones',
        icon: SettingsIcon,
        href: '/app/admin/configuraciones'
      },
      {
        title: 'Punto de venta',
        icon: ZapIcon,
        href: '/app/admin/venta-backoffice'
      },
      {
        title: 'Ventas a SAP',
        icon: ZapIcon,
        href: '/app/admin/sap-sales'
      },
      {
        title: 'Documentos',
        icon: Receipt,
        href: '/app/admin/generacion-documento'
      },
    ]
  },
  {
    subheader: 'Ecommerce',
    items: [
      {
        title: 'Banner Principal',
        icon: ImageIcon,
        href: '/app/admin/banners'
      },
      {
        title: 'Caluga Principal',
        icon: LayoutIcon,
        href: '/app/admin/banner-secundario'
      },
      {
        title: 'Footer',
        icon: PanoramaIcon,
        href: '/app/admin/banner-footer'
      },
      {
        title: 'Banner Categorías',
        icon: StyleIcon,
        href: '/app/admin/banner-categorias'
      },
      {
        title: 'Banner Marcas',
        icon: ViewCarouselIcon,
        href: '/app/admin/banner-marcas'
      },
      {
        title: 'Carrusel',
        icon: ColumnsIcon,
        href: '/app/admin/carruseles'
      },
      {
        title: 'Costos Despacho Programado',
        icon: RequestQuote,
        href: '/app/admin/costos-despacho-programado'
      },
      {
        title: 'Blogs',
        icon: BlogIcon,
        href: '/app/admin/blogs'
      },
      {
        title: 'Fechas Lista Negra',
        icon: EventBusyIcon,
        href: '/app/admin/fechas-lista-negra'
      },
      {
        title: 'Polígonos',
        icon: MapPinIcon,
        href: '/app/admin/poligonos'
      },
      {
        title: 'Meta Tags',
        icon: CodeIcon,
        href: '/app/admin/meta-tags'
      },
      {
        title: 'Tipos Delivery Programado',
        icon: TruckIcon,
        href: '/app/admin/tipos-delivery-programado'
      },
      {
        title: 'Categorías Ecommerce',
        icon: ListIcon,
        href: '/app/admin/categorias-ecommerce'
      },
    ]
  },
];

const salerNavConfig = [
  {
    subheader: 'Administrativo',
    items: [
      {
        title: 'Movimientos de stock',
        icon: ActivityIcon,
        href: '/app/admin/movimientos-de-stock'
      },
      {
        title: 'Pedidos',
        icon: FileIcon,
        href: '/app/admin/pedidos'
      },
      {
        title: 'Punto de venta',
        icon: ZapIcon,
        href: '/app/admin/venta-backoffice'
      },
    ]
  }
];

const adminFranchiseNavConfig = [
  // {
  //   subheader: 'Analíticas',
  //   items: [
  //     {
  //       title: 'Socios Club Líquidos',
  //       icon: UsersIcon,
  //       href: '/app/reportes/socios',
  //     },
  //     {
  //       title: 'Ventas',
  //       icon: BarChartIcon,
  //       href: '/app/reportes/ventas',
  //     },
  //     {
  //       title: 'Rankings',
  //       icon: StarIcon,
  //       href: '/app/reportes/rankings',
  //     },
  //   ]
  // },
  {
    subheader: 'Administrativo',
    items: [
      {
        title: 'Administradores',
        icon: UserIcon,
        href: '/app/admin/admins'
      },
      {
        title: 'Locales',
        icon: HomeIcon,
        href: '/app/admin/locales'
      },
      {
        title: 'Movimientos de Stock',
        icon: ActivityIcon,
        href: '/app/admin/movimientos-de-stock'
      },
      {
        title: 'Pedidos de Tienda',
        icon: TruckIcon,
        href: '/app/admin/pedidos-de-tienda'
      },
      {
        title: 'Pedidos',
        icon: FileIcon,
        href: '/app/admin/pedidos'
      },
      {
        title: 'Punto de venta',
        icon: ZapIcon,
        href: '/app/admin/venta-backoffice'
      },
    ]
  }
];

const salerFranchiseNavConfig = [
  {
    subheader: 'Administrativo',
    items: [
      {
        title: 'Movimientos de stock',
        icon: ActivityIcon,
        href: '/app/admin/movimientos-de-stock'
      },
      {
        title: 'Pedidos',
        icon: FileIcon,
        href: '/app/admin/pedidos'
      },
      {
        title: 'Punto de venta',
        icon: ZapIcon,
        href: '/app/admin/venta-backoffice'
      },
    ]
  }
];

function renderNavItems({ user, items, ...rest }) {
  return (
    <List disablePadding>
      {items.sort((a, b) => a.title.localeCompare(b.title)).reduce(
        (acc, item) => reduceChildRoutes({
          user, acc, item, ...rest,
        }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  user, acc, pathname, item, depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          user,
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else if (item.href !== '/app/admin/venta-backoffice' || user.Store.backoffice_sale_active) {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    maxWidth: '300px'
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const renderConfigNavBar = (config) => (
    <List
      key={config.subheader}
      subheader={(
        <ListSubheader disableGutters disableSticky>
          {config.subheader}
        </ListSubheader>
      )}
    >
      {renderNavItems({
        user,
        items: config.items,
        pathname: location.pathname
      })}
    </List>
  );

  const renderNavBar = () => {
    if (user.role === ROLE_VENDOR) {
      if (user.Store.stock_update_active) {
        // Store orders
        const storeOrdersOption = salerNavConfig[0].items.find((i) => i.title === 'Pedidos de tienda');
        if (!storeOrdersOption) {
          salerNavConfig[0].items.push({
            title: 'Pedidos de tienda',
            icon: TruckIcon,
            href: '/app/admin/pedidos-de-tienda'
          });
        }
      }
      return salerNavConfig.map((config) => renderConfigNavBar(config));
    }
    if (user.role === ROLE_VENDOR_FRANCHISE) {
      if (user.Store.stock_update_active) {
        // Store orders
        const storeOrdersOption = salerFranchiseNavConfig[0].items.find((i) => i.title === 'Pedidos de tienda');
        if (!storeOrdersOption) {
          salerFranchiseNavConfig[0].items.push({
            title: 'Pedidos de tienda',
            icon: TruckIcon,
            href: '/app/admin/pedidos-de-tienda'
          });
        }
      }
      return salerFranchiseNavConfig.map((config) => renderConfigNavBar(config));
    }
    if (user.role === ROLE_ADMIN_FRANCHISE) {
      return adminFranchiseNavConfig.map((config) => (
        renderConfigNavBar(config)
      ));
    }
    return adminNavConfig.map((config) => (
      renderConfigNavBar(config)
    ));
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.name}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {renderNavBar()}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
