/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app/reportes/socios" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reportes/socios" />
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reportes/socios',
        component: lazy(() => import('src/views/reports/Members'))
      },
      {
        exact: true,
        path: '/app/reportes/ventas',
        component: lazy(() => import('src/views/reports/Sales'))
      },
      {
        exact: true,
        path: '/app/reportes/rankings',
        component: lazy(() => import('src/views/reports/Rankings'))
      },
      {
        exact: true,
        path: '/app/admin/clientes',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/admin/clientes/busqueda-avanzada',
        component: lazy(() => import('src/views/management/CustomerAdvancedSearchView'))
      },
      {
        exact: true,
        path: '/app/admin/clientes/exportar',
        component: lazy(() => import('src/views/management/CustomerExportView'))
      },
      {
        exact: true,
        path: '/app/admin/clientes/nuevo',
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/admins',
        component: lazy(() => import('src/views/management/AdminListView'))
      },
      {
        exact: true,
        path: '/app/admin/admins/nuevo',
        component: lazy(() => import('src/views/management/AdminCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/admins/:adminId/edit',
        component: lazy(() => import('src/views/management/AdminEditView'))
      },
      {
        exact: true,
        path: '/app/admin/clientes/:customerId',
        component: lazy(() => import('src/views/management/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/admin/clientes/:customerId/pago-tarjeta-credito/:chargeId',
        component: lazy(() => import('src/views/management/CustomerDetailsView/DetailOneClickPayment'))
      },
      {
        exact: true,
        path: '/app/admin/clientes/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/admin/proveedores',
        component: lazy(() => import('src/views/management/SuppliersListView'))
      },
      {
        exact: true,
        path: '/app/admin/proveedores/:supplierId/edit',
        component: lazy(() => import('src/views/management/SuppliersEditView'))
      },
      {
        exact: true,
        path: '/app/admin/proveedores/nuevo',
        component: lazy(() => import('src/views/management/SuppliersCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/proveedores/productos/importar',
        component: lazy(() => import('src/views/management/SupplierProductsImportView'))
      },
      {
        exact: true,
        path: '/app/admin/proveedores/productos/exportar',
        component: lazy(() => import('src/views/management/SupplierProductsExportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos',
        component: lazy(() => import('src/views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/exportar',
        component: lazy(() => import('src/views/management/ProductExportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/:productId/edit',
        component: lazy(() => import('src/views/management/ProductEditView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/nuevo',
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/importar',
        component: lazy(() => import('src/views/management/ProductsImportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/orden',
        component: lazy(() => import('src/views/management/ProductsImportOrderView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/exportar-orden',
        component: lazy(() => import('src/views/management/ProductsExportOrderView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/importar-packs',
        component: lazy(() => import('src/views/management/PackImportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/importar-tipo',
        component: lazy(() => import('src/views/management/ProductClassImportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/active',
        component: lazy(() => import('src/views/management/ProductImportActiveView'))
      },
      {
        exact: true,
        path: '/app/admin/productos/pedidosya',
        component: lazy(() => import('src/views/management/PedidosYaExportView'))
      },
      {
        exact: true,
        path: '/app/admin/rankings/update',
        component: lazy(() => import('src/views/management/ProductsRankingUpdate'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-precios',
        component: lazy(() => import('src/views/management/PriceListListView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-precios/nueva',
        component: lazy(() => import('src/views/management/PriceListCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-precios/importar',
        component: lazy(() => import('src/views/management/PriceListImportView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-precios/:priceListId/detalle',
        component: lazy(() => import('src/views/management/PriceListDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-precios/:priceListId/detalle/importar',
        component: lazy(() => import('src/views/management/PriceListDetailImport'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-precios/:priceListId/detalle/exportar',
        component: lazy(() => import('src/views/management/PriceListDetailExport'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-costos',
        component: lazy(() => import('src/views/management/CostListListView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-costos/nueva',
        component: lazy(() => import('src/views/management/CostListCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-costos/importar',
        component: lazy(() => import('src/views/management/CostListImportView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-costos/:costListId/detalle',
        component: lazy(() => import('src/views/management/CostListDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-costos/:costListId/detalle/importar',
        component: lazy(() => import('src/views/management/CostListDetailImport'))
      },
      {
        exact: true,
        path: '/app/admin/listas-de-costos/:costListId/detalle/exportar',
        component: lazy(() => import('src/views/management/CostListDetailExport'))
      },
      {
        exact: true,
        path: '/app/admin/listas-precios-franquicias',
        component: lazy(() => import('src/views/management/FranchisePriceListListView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-precios-franquicias/nueva',
        component: lazy(() => import('src/views/management/FranchisePriceListCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-precios-franquicias/:id/detalle',
        component: lazy(() => import('src/views/management/FranchisePriceListDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/listas-precios-franquicias/:id/detalle/importar',
        component: lazy(() => import('src/views/management/FranchisePriceListDetailImport'))
      },
      {
        exact: true,
        path: '/app/admin/listas-precios-franquicias/:id/detalle/exportar',
        component: lazy(() => import('src/views/management/FranchisePriceListDetailExport'))
      },
      {
        exact: true,
        path: '/app/admin/productos-ordenes-de-compra',
        component: lazy(() => import('src/views/management/PurchaseOrderProductsListView'))
      },
      {
        exact: true,
        path: '/app/admin/productos-ordenes-de-compra/importar',
        component: lazy(() => import('src/views/management/PurchaseOrderProductsImportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos-ordenes-de-compra/exportar',
        component: lazy(() => import('src/views/management/PurchaseOrderProductsExportView'))
      },
      {
        exact: true,
        path: '/app/admin/surtido-ordenes-de-compra/importar',
        component: lazy(() => import('src/views/management/PurchaseOrderMixedImportView'))
      },
      {
        exact: true,
        path: '/app/admin/surtido-ordenes-de-compra/exportar',
        component: lazy(() => import('src/views/management/PurchaseOrderMixedExportView'))
      },
      {
        exact: true,
        path: '/app/admin/dias-inventario-ordenes-de-compra/importar',
        component: lazy(() => import('src/views/management/PurchaseOrderInventoryDaysImportView'))
      },
      {
        exact: true,
        path: '/app/admin/dias-inventario-ordenes-de-compra/exportar',
        component: lazy(() => import('src/views/management/PurchaseOrderInventoryDaysExportView'))
      },
      {
        exact: true,
        path: '/app/admin/ordenes-de-compra',
        component: lazy(() => import('src/views/management/PurchaseOrderListView'))
      },
      {
        exact: true,
        path: '/app/admin/ordenes-de-compra/generar',
        component: lazy(() => import('src/views/management/PurchaseOrderGenerateView'))
      },
      {
        exact: true,
        path: '/app/admin/orden-de-compra/:id',
        component: lazy(() => import('src/views/management/PurchaseOrderDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/facturas-de-compra',
        component: lazy(() => import('src/views/management/PurchaseInvoiceListView'))
      },
      {
        exact: true,
        path: '/app/admin/facturas-de-compra/exportar',
        component: lazy(() => import('src/views/management/PurchaseInvoiceExportView'))
      },
      {
        exact: true,
        path: '/app/admin/facturas-de-compra/:purchaseInvoiceId',
        component: lazy(() => import('src/views/management/PurchaseInvoiceDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/notas-de-credito-proveedores',
        component: lazy(() => import('src/views/management/SuppliersCreditNotesListView'))
      },
      {
        exact: true,
        path: '/app/admin/notas-de-credito-proveedores/detalle/:id',
        component: lazy(() => import('src/views/management/SuppliersCreditNoteDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/notas-de-credito-proveedores/nueva',
        component: lazy(() => import('src/views/management/SuppliersCreditNoteCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/notas-de-credito-proveedores/exportar',
        component: lazy(() => import('src/views/management/SuppliersCreditNotesExportView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos-de-tienda',
        component: lazy(() => import('src/views/management/StoreOrdersListView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos-de-tienda/generar',
        component: lazy(() => import('src/views/management/StoreOrdersGenerateView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos-de-tienda/transito',
        component: lazy(() => import('src/views/management/StoreOrdersTransitListView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos-de-tienda/transito/:id',
        component: lazy(() => import('src/views/management/StoreOrdersTransitDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/pedido-de-tienda/:id',
        component: lazy(() => import('src/views/management/StoreOrdersDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/normalizar',
        component: lazy(() => import('src/views/management/OrderFixView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/cuadratura',
        component: lazy(() => import('src/views/management/OrderQuadrature'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/diario',
        component: lazy(() => import('src/views/management/OrderSalesDailyExport'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/actualizar/estados',
        component: lazy(() => import('src/views/management/OrderUpdateStatusView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/exportar',
        component: lazy(() => import('src/views/management/OrderExportView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/exportar/pagos',
        component: lazy(() => import('src/views/management/OrderPaymentExportView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/exportar/movimientos',
        component: lazy(() => import('src/views/management/OrderMovementsExportView'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/exportar/pagos/diario',
        component: lazy(() => import('src/views/management/OrderSalesDailyExport'))
      },
      {
        exact: true,
        path: '/app/admin/pedidos/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/admin/categorias',
        component: lazy(() => import('src/views/management/GroupListView'))
      },
      {
        exact: true,
        path: '/app/admin/categorias/:groupId/edit',
        component: lazy(() => import('src/views/management/GroupEditView'))
      },
      {
        exact: true,
        path: '/app/admin/categorias/nuevo',
        component: lazy(() => import('src/views/management/GroupCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/maquinas-redelcom',
        component: lazy(() => import('src/views/management/RdcMachine/RdcMachineListView'))
      },
      {
        exact: true,
        path: '/app/admin/maquinas-redelcom/:machineId/edit',
        component: lazy(() => import('src/views/management/RdcMachine/RdcMachineEditView'))
      },
      {
        exact: true,
        path: '/app/admin/maquinas-redelcom/nueva',
        component: lazy(() => import('src/views/management/RdcMachine/RdcMachineCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/suscripciones',
        component: lazy(() => import('src/views/management/SubscriptionListView'))
      },
      {
        exact: true,
        path: '/app/admin/suscripciones/:subscriptionId/edit',
        component: lazy(() => import('src/views/management/SubscriptionEditView'))
      },
      {
        exact: true,
        path: '/app/admin/suscripciones/nuevo',
        component: lazy(() => import('src/views/management/SubscriptionCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/locales',
        component: lazy(() => import('src/views/management/StoreListView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/nuevo',
        component: lazy(() => import('src/views/management/StoreCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/:storeId/edit',
        component: lazy(() => import('src/views/management/StoreEditView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/:storeId/productos-destacados',
        component: lazy(() => import('src/views/management/StorePopularProductsDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/:storeId/stock',
        component: lazy(() => import('src/views/management/StoreStockView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/:storeId/stock/:productId/',
        component: lazy(() => import('src/views/management/StoreStockEditView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/importar-stock',
        component: lazy(() => import('src/views/management/StoreStockImportView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/:storeId/importar-stock',
        component: lazy(() => import('src/views/management/StoreStockImportView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/importar-productos-destacados',
        component: lazy(() => import('src/views/management/PopularProductsImportView'))
      },
      {
        exact: true,
        path: '/app/admin/locales/exportar-horarios-locales',
        component: lazy(() => import('src/views/management/StoreScheduleExport'))
      },
      {
        exact: true,
        path: '/app/admin/locales/importar-horarios-locales',
        component: lazy(() => import('src/views/management/StoreScheduleImport'))
      },
      {
        exact: true,
        path: '/app/admin/movimientos-de-stock',
        component: lazy(() => import('src/views/management/StockMovementsListView'))
      },
      {
        exact: true,
        path: '/app/admin/movimientos-de-stock/exportar',
        component: lazy(() => import('src/views/management/StockMovementsExportView'))
      },
      {
        exact: true,
        path: '/app/admin/cupones',
        component: lazy(() => import('src/views/management/CouponListView'))
      },
      {
        exact: true,
        path: '/app/admin/cupones/:couponId/edit',
        component: lazy(() => import('src/views/management/CouponEditView'))
      },
      {
        exact: true,
        path: '/app/admin/cupones/:couponId/detalle',
        component: lazy(() => import('src/views/management/CouponDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/cupones/nuevo',
        component: lazy(() => import('src/views/management/CouponCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/cupones/importar',
        component: lazy(() => import('src/views/management/CouponImportView'))
      },
      {
        exact: true,
        path: '/app/admin/cupones/exportar',
        component: lazy(() => import('src/views/management/CouponExportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos-cupones/importar',
        component: lazy(() => import('src/views/management/CouponImportProductView'))
      },
      {
        exact: true,
        path: '/app/admin/clientes-cupones/importar',
        component: lazy(() => import('src/views/management/CouponImportCustomerView'))
      },
      {
        exact: true,
        path: '/app/admin/productos-cupones/exportar',
        component: lazy(() => import('src/views/management/CouponExportProductView'))
      },
      {
        exact: true,
        path: '/app/admin/clientes-cupones/exportar',
        component: lazy(() => import('src/views/management/CouponExportCustomerView'))
      },
      {
        exact: true,
        path: '/app/admin/configuraciones',
        component: lazy(() => import('src/views/management/SettingListView'))
      },
      {
        exact: true,
        path: '/app/admin/configuraciones/:settingId/edit',
        component: lazy(() => import('src/views/management/SettingEditView'))
      },
      {
        exact: true,
        path: '/app/admin/carruseles',
        component: lazy(() => import('src/views/management/CarouselListView'))
      },
      {
        exact: true,
        path: '/app/admin/banner-carruseles/:name',
        component: lazy(() => import('src/views/management/CarruselBannersListView'))
      },
      {
        exact: true,
        path: '/app/admin/carruseles/nuevo',
        component: lazy(() => import('src/views/management/CarouselCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/carruseles/importar',
        component: lazy(() => import('src/views/management/CarouselImportView'))
      },
      {
        exact: true,
        path: '/app/admin/carruseles/descargar',
        component: lazy(() => import('src/views/management/CarouselExportView'))
      },
      {
        exact: true,
        path: '/app/admin/carruseles/borrar',
        component: lazy(() => import('src/views/management/CarouselDeleteView'))
      },
      {
        exact: true,
        path: '/app/admin/productos-carruseles/importar',
        component: lazy(() => import('src/views/management/CarouselProductsImportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos-carruseles/descargar',
        component: lazy(() => import('src/views/management/CarouselProductsExportView'))
      },
      {
        exact: true,
        path: '/app/admin/productos-carruseles/borrar',
        component: lazy(() => import('src/views/management/CarouselProductsDeleteView'))
      },
      {
        exact: true,
        path: '/app/admin/costos-despacho-programado',
        component: lazy(() => import('src/views/management/ProgrammedDeliveryCostsListView'))
      },
      {
        exact: true,
        path: '/app/admin/banners',
        component: lazy(() => import('src/views/management/BannersListView'))
      },
      {
        exact: true,
        path: '/app/admin/banners/importar',
        component: lazy(() => import('src/views/management/BannersImportView'))
      },
      {
        exact: true,
        path: '/app/admin/banners/descargar',
        component: lazy(() => import('src/views/management/BannersExportView'))
      },
      {
        exact: true,
        path: '/app/admin/banners/borrar',
        component: lazy(() => import('src/views/management/BannersDeleteView'))
      },
      {
        exact: true,
        path: '/app/admin/banners/subir-imagen',
        component: lazy(() => import('src/views/management/BannersImageUploadView'))
      },
      {
        exact: true,
        path: '/app/admin/banner-secundario',
        component: lazy(() => import('src/views/management/SecondaryBannerListView'))
      },
      {
        exact: true,
        path: '/app/admin/banner-marcas',
        component: lazy(() => import('src/views/management/BrandsBannerListView'))
      },
      {
        exact: true,
        path: '/app/admin/banner-footer',
        component: lazy(() => import('src/views/management/FooterBannerListView'))
      },
      {
        exact: true,
        path: '/app/admin/promociones',
        component: lazy(() => import('src/views/management/PromotionListView'))
      },
      {
        exact: true,
        path: '/app/admin/promociones-peya',
        component: lazy(() => import('src/views/management/PedidosyaPromotionsView'))
      },
      {
        exact: true,
        path: '/app/admin/promociones/nuevo',
        component: lazy(() => import('src/views/management/PromotionCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/promociones/:promotionId/edit',
        component: lazy(() => import('src/views/management/PromotionEditView'))
      },
      {
        exact: true,
        path: '/app/admin/promociones/importar',
        component: lazy(() => import('src/views/management/PromotionImportView'))
      },
      {
        exact: true,
        path: '/app/admin/liquidazos',
        component: lazy(() => import('src/views/management/SelloutListView'))
      },
      {
        exact: true,
        path: '/app/admin/liquidazos/importar',
        component: lazy(() => import('src/views/management/SelloutImportView'))
      },
      {
        exact: true,
        path: '/app/admin/liquidazos/exportar',
        component: lazy(() => import('src/views/management/SelloutExportView'))
      },
      {
        exact: true,
        path: '/app/admin/liquidazos/nuevo',
        component: lazy(() => import('src/views/management/SelloutCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/liquidazos/:selloutId/editar',
        component: lazy(() => import('src/views/management/SelloutEditView'))
      },
      {
        exact: true,
        path: '/app/admin/blogs',
        component: lazy(() => import('src/views/management/BlogListView'))
      },
      {
        exact: true,
        path: '/app/admin/blogs/nuevo',
        component: lazy(() => import('src/views/management/BlogCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/blogs/:blogId/edit',
        component: lazy(() => import('src/views/management/BlogEditView'))
      },
      {
        exact: true,
        path: '/app/admin/venta-backoffice',
        component: lazy(() => import('src/views/management/BackOfficeSaleTypeClientView'))
      },
      {
        exact: true,
        path: '/app/admin/venta-backoffice/perfil',
        component: lazy(() => import('src/views/management/BackOfficeSalePartnerView'))
      },
      {
        exact: true,
        path: '/app/admin/venta-backoffice/compra',
        component: lazy(() => import('src/views/management/BackOfficeSaleBsaleView'))
      },
      {
        exact: true,
        path: '/app/admin/venta-backoffice/orden-delivery/:deliveryType',
        component: lazy(() => import('src/views/management/BackOfficeSaleDeliveryOrderView'))
      },
      {
        exact: true,
        path: '/app/admin/venta-backoffice/factura',
        component: lazy(() => import('src/views/management/BackOfficeBilling'))
      },
      {
        exact: true,
        path: '/app/admin/venta-backoffice/confirmacion',
        component: lazy(() => import('src/views/management/BackOfficeSaleConfirm'))
      },
      {
        exact: true,
        path: '/app/admin/poligonos/',
        component: lazy(() => import('src/views/management/StorePolygonsView'))
      },
      {
        exact: true,
        path: '/app/admin/sap-sales',
        component: lazy(() => import('src/views/management/SapInvoicesView'))
      },
      {
        exact: true,
        path: '/app/admin/fechas-lista-negra',
        component: lazy(() => import('src/views/management/BlacklistDatesList'))
      },
      {
        exact: true,
        path: '/app/admin/generacion-documento',
        component: lazy(() => import('src/views/management/DocumentGenerationListView'))
      },
      {
        exact: true,
        path: '/app/admin/generacion-documento/nuevo',
        component: lazy(() => import('src/views/management/DocumentGenerationCreateView'))
      },
      {
        exact: true,
        path: '/app/admin/meta-tags',
        component: lazy(() => import('src/views/management/MetaTagsView'))
      },
      {
        exact: true,
        path: '/app/admin/generacion-documento/detalle/:id',
        component: lazy(() => import('src/views/management/DocumentGenerationDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/tipos-delivery-programado',
        component: lazy(() => import('src/views/management/ProgrammedDeliveryShippingCosts'))
      },
      {
        exact: true,
        path: '/app/admin/categorias-ecommerce',
        component: lazy(() => import('src/views/management/EcommerceCategoryView'))
      },
      {
        exact: true,
        path: '/app/admin/filtros-categorias-ecommerce/:id',
        component: lazy(() => import('src/views/management/EcommerceCategoryFiltersView'))
      },
      {
        exact: true,
        path: '/app/admin/banner-categorias',
        component: lazy(() => import('src/views/management/CategoryBannerListView'))
      },
      {
        exact: true,
        path: '/app/admin/banner-categorias/:name',
        component: lazy(() => import('src/views/management/CategoryBannerDetailListView'))
      },
      {
        exact: true,
        path: '/app/admin',
        component: () => <Redirect to="/app/admin/admins" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
