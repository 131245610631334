const { lowerCaseNoAccents } = require('./utils');

const BUY_TYPE_SOCIO = 'socio';
const BUY_TYPE_NO_SOCIO = 'no-socio';
const BUY_TYPE_RAPPI = 'rappi';
const BUY_TYPE_RAPPI_TURBO = 'rappi-turbo';
const BUY_TYPE_RAPPI_PAYLESS = 'rappi-payless';
const BUY_TYPE_CORNERSHOP = 'cornershop';
const BUY_TYPE_UBEREATS = 'ubereats';
const BUY_TYPE_PEDIDOSYA = 'pedidosya';
const BUY_TYPE_AUX_DELIVERY = 'delivery';
const BUY_TYPE_GOODMEAL = 'goodmeal';

const COUPON_TYPE_DIFF = 'diff';
const COUPON_TYPE_PERCENTAGE = 'percentage';

const PAYMENT_TYPE_METHOD = 'METODO DE PAGO';
const PAYMENT_TYPE_RAPPI = 'RAPPI';
const PAYMENT_TYPE_RAPPI_TURBO = 'RAPPI TURBO';
const PAYMENT_TYPE_RAPPI_PAYLESS = 'RAPPI PAYLESS';
const PAYMENT_TYPE_CORNERSHOP = 'CORNERSHOP';
const PAYMENT_TYPE_UBEREATS = 'UBEREATS';
const PAYMENT_TYPE_PEDIDOSYA = 'PEDIDOSYA';
const PAYMENT_TYPE_EFECTIVO = 'EFECTIVO';
const PAYMENT_TYPE_TARJETA_DEBITO = 'TARJETA DE DEBITO';
const PAYMENT_TYPE_TARJETA_CREDITO = 'TARJETA DE CREDITO';
const PAYMENT_TYPE_TRANSFERENCIA = 'TRANSFERENCIA BANCARIA';
const PAYMENT_TYPE_WALLET = 'WALLET';
const PAYMENT_TYPE_MERCADO_PAGO = 'MERCADO PAGO';
const PAYMENT_TYPE_MACH = 'MACH';
const PAYMENT_TYPE_WEBPAY = 'WEBPAY';
const PAYMENT_TYPE_WEBPAY_CREDITO = 'WEBPAY CREDITO';
const PAYMENT_TYPE_WEBPAY_DEBITO = 'WEBPAY DEBITO';
const PYAMENT_TYPE_WEBPAY_PREPAGO = 'WEBPAY PREPAGO';
const PAYMENT_TYPE_OTRO = 'OTRO';
const PAYMENT_TYPE_NC = 'NOTA DE CREDITO';
const PAYMENT_TYPE_GOODMEAL = 'GOODMEAL';
const PAYMENT_TYPE_FREE = 'FREE';
const PAYMENT_TYPE_FPAY = 'FPAY';
const PAYMENT_TYPE_CENCOSUD = 'CENCOSUD';
const PAYMENT_TYPE_CHEK = 'CHEK';
const PAYMENT_TYPE_REDPAY = 'REDPAY';
const PAYMENT_TYPE_MARKETPLACE_WALMART = 'MARKETPLACE WALMART';
const PAYMENT_TYPE_WEBPAY_ONECLICK_CREDITO = 'WEBPAY ONECLICK CREDITO';
const PAYMENT_TYPE_WEBPAY_ONECLICK_DEBITO = 'WEBPAY ONECLICK DEBITO';
const PAYMENT_TYPE_WEBPAY_ONECLICK_PREPAGO = 'WEBPAY ONECLICK PREPAGO';

const SETTINGS_KEY_MIN_BULK_PRICE = 'PRECIO_MIN_MAYORISTA';

const STATUS_PENDIENTE = 'PENDIENTE';
const STATUS_APROBADO = 'APROBADO';
const STATUS_RECHAZADO = 'RECHAZADO';
const STATUS_CANCELADO = 'CANCELADO';
const STATUS_GUARDADO = 'GUARDADO';
const STATUS_ERROR = 'ERROR';
const STATUS_REVISADO = 'REVISADO';
const STATUS_PAGANDO = 'PAGANDO';
const STATUS_PENDING = 'PENDING';
const STATUS_ACEPTADO = 'ACEPTADO';
const STATUS_ANULADO = 'ANULADO';
const STATUS_FALLA_CANCELACIÓN = 'FALLA CANCELACIÓN';
const STATUS_VERIFICANDO = 'VERIFICANDO';
const STATUS_BSALE_ERROR = 'BSALE ERROR';
const STATUS_COMPLETADO = 'COMPLETADO';
const STATUS_REVISADO_MANUAL = 'REVISADO MANUAL';
const STATUS_BILLING_PROCESSING = 'BILLING PROCESSING';
const STATUS_FINISHED = 'FINISHED';
const STATUS_INGRESADO = 'INGRESADO';

const DELIVERY_STATUS_PENDIENTE = 'PENDIENTE';
const DELIVERY_STATUS_EN_PROCESO = 'EN PROCESO';
const DELIVERY_STATUS_POR_PREPARAR = 'POR PREPARAR';
const DELIVERY_STATUS_POR_ENTREGAR = 'POR ENTREGAR';
const DELIVERY_STATUS_PREPARADO = 'PREPARADO';
const DELIVERY_STATUS_EN_RUTA = 'EN RUTA';
const DELIVERY_STATUS_ENTREGADO = 'ENTREGADO';
const DELIVERY_STATUS_NO_ENTREGADO = 'NO ENTREGADO';

const DELIVERY_TIME_PROGRAMMED = 'programmed';
const DELIVERY_TIME_IMMEDIATE = 'immediate';
const DELIVERY_TYPE_DELIVERY = 'delivery';
const DELIVERY_TYPE_PICKUP = 'pickup';

const DELIVERY_PROGRAMMED_TYPE_SAME_DAY = 'same_day';
const DELIVERY_PROGRAMMED_TYPE_SAME_DAY_TODAY = 'today';
const DELIVERY_PROGRAMMED_TYPE_SAME_DAY_TOMORROW = 'tomorrow';
const DELIVERY_PROGRAMMED_TYPE_NEXT_DAY = 'next_day';
const DELIVERY_PROGRAMMED_TYPE_IN_DAYS = 'in_days';
const DELIVERY_PROGRAMMED_TYPE_CUSTOM_DAY = 'custom_day';
const DELIVERY_PROGRAMMED_TYPE_THREE_DAYS = 'three';
const DELIVERY_PROGRAMMED_TYPE_FIVE_DAYS = 'five';
const DELIVERY_PROGRAMMED_TYPE_SEVEN_DAYS = 'seven';
const DELIVERY_PROGRAMMED_TYPE_TEN_DAYS = 'ten';

const INTERNET_STORE_ID = 9;
const CD_STORE_ID = 569;
const CASA_MATRIZ_STORE_ID = 4;
const CHACABUCO_STORE_ID = 5;
const BILBAO_STORE_ID = 3;
const CHIGUAYANTE_2_STORE_ID = 574;
const LA_DEHESA_TURBO_RAPPI_STORE_ID = 551;
const MERCADO_LIBRE_STORE_ID = 571;
const FRANCHISE_STORE_IDS = [
  19, // Nataniel Cox
  573, // Geronimo de Alderete
  578, // Bilbao Franquicia
  576, // Viña del Mar 3 Franquicia
  577, // Vitacura La Aurora Franquicia
];
const SAN_PABLO_STORE_ID = 25;

const ENTERED_PURCHASE_ORDER = 'INGRESADA';
const GENERATED_PURCHASE_ORDER = 'GENERADA';
const CANCELLED_PURCHASE_ORDER = 'CANCELADA';
const FAILED_PURCHASE_ORDER = 'FALLIDA';
const PROCESSING_PURCHASE_ORDER = 'EN PROCESO';
const TERMINATED_PURCHASE_ORDER = 'TERMINADA';
const RE_OPEN_PURCHASE_ORDER = 'RE ABRIR';

const INITIATED_PURCHASE_INVOICE = 'INICIADA';
const FINISHED_PURCHASE_INVOICE = 'TERMINADA';

const ENTERED_STORE_ORDER = 'INGRESADA';
const GENERATED_STORE_ORDER = 'GENERADA';
const CANCELLED_STORE_ORDER = 'CANCELADA';
const RECEIVED_STORE_ORDER = 'RECIBIDA';
const FAILED_STORE_ORDER = 'FALLIDA';
const PROCESSING_STORE_ORDER = 'EN PROCESO';
const PREPARED_STORE_ORDER = 'PREPARADA';
const TO_PREPARE_STORE_ORDER = 'POR PREPARAR';
const RE_OPEN_STORE_ORDER = 'RE ABRIR';

const DOCUMENT_TYPE_BOLETA = 'Boleta';
const DOCUMENT_TYPE_FACTURA = 'Factura';
const DOCUMENT_TYPE_NOTA_DE_CREDITO = 'Nota de Crédito';
const DOCUMENT_TYPE_GUIA_DE_DESPACHO = 'Guía de Despacho';

const CASH_MOVEMENT_TYPE_INCOME = 'INGRESO';
const CASH_MOVEMENT_TYPE_OUTCOME = 'EGRESO';

const TZ_AMERICA_SANTIAGO = 'America/Santiago';
const PROCESSING = 'PROCESANDO';

const NORMAL_SUPPLY_TYPE = 'NORMAL';
const CENTRALIZED_SUPPLY_TYPE = 'CENTRALIZADO';
const DIRECT_SUPPLY_TYPE = 'DIRECTO';
const OTHER_SUPPLY_TYPE = 'NA';
const FORCE_CENTRALIZED_SUPPLY_TYPE = 'FORZAR CENTRALIZADO';
const FORCE_DIRECT_SUPPLY_TYPE = 'FORZAR DIRECTO';

const MOVEMENT_REASON = [
  'BOLETA PENDIENTE RDC',
  'FALTANTE',
  'INGRESO FACTURA',
  'INGRESO GDI',
  'INGRESO NC',
  'INVENTARIO',
  'INVENTARIO GENERAL',
  'INVENTARIO SELECTIVO',
  'MERMA COMERCIAL',
  'MERMA OPERATIVA',
  'MERMA PLAGAS',
  'MERMA DESASTRE NATURAL',
  'RETIRA CLIENTE (CAMBIO)',
  'ROBO',
  'SOBRANTE',
  'VENTA CRUZADA',
  'PENDIENTE POR REVISAR',
  'ShipHero',
];

const CENCOSUD_STATUS_UNABLE_TO_FULFILL = 'unable_to_fulfill';

const PROVIDER_TRANSBANK = 'TRANSBANK';
const PROVIDER_TRANSBANK_SMARTPOS = 'TRANSBANK-SMARTPOS';
const PROVIDER_REDELCOM = 'REDELCOM';
const PROVIDER_ECOMMERCE = 'ECOMMERCE';

const DOC_TYPES = [
  {
    name: DOCUMENT_TYPE_BOLETA, value: lowerCaseNoAccents(DOCUMENT_TYPE_BOLETA),
  },
  {
    name: DOCUMENT_TYPE_FACTURA, value: lowerCaseNoAccents(DOCUMENT_TYPE_FACTURA),
  },
  {
    name: DOCUMENT_TYPE_NOTA_DE_CREDITO, value: lowerCaseNoAccents(DOCUMENT_TYPE_NOTA_DE_CREDITO),
  },
  {
    name: DOCUMENT_TYPE_GUIA_DE_DESPACHO, value: lowerCaseNoAccents(DOCUMENT_TYPE_GUIA_DE_DESPACHO),
  },
];

const FRANCHISE_PROD_TYPE_DIRECT = 'Directo';
const FRANCHISE_PROD_TYPE_CD = 'Centro distribucion';
const FRANCHISE_FEE_TYPE_DIRECT_DESCRIPTION = 'SERVICIO FEE FRANQUICIA';
const FRANCHISE_FEE_TYPE_CD_DESCRIPTION = 'FEE LOGÍSTICO FRANQUICIA';

const FRANCHISE_TYPE = [{
  name: FRANCHISE_PROD_TYPE_DIRECT,
  value: lowerCaseNoAccents(FRANCHISE_PROD_TYPE_DIRECT),
}, {
  name: FRANCHISE_PROD_TYPE_CD,
  value: lowerCaseNoAccents(FRANCHISE_PROD_TYPE_CD),
}];

const INVOICE_TYPE_GENERAL = 'Documento uso general';
const INVOICE_TYPE_FRANCHISE = 'Documento franquicia';

const INVOICE_TYPE = [{
  name: INVOICE_TYPE_GENERAL,
  value: lowerCaseNoAccents(INVOICE_TYPE_GENERAL),
}, {
  name: INVOICE_TYPE_FRANCHISE,
  value: lowerCaseNoAccents(INVOICE_TYPE_FRANCHISE),
}];

const ROLE_ADMIN = 'ADMIN';
const ROLE_VENDOR = 'VENDEDOR';
const ROLE_EDITOR = 'EDITOR';
const ROLE_ADMIN_FRANCHISE = 'ADMIN FRANQUICIA';
const ROLE_VENDOR_FRANCHISE = 'VENDEDOR FRANQUICIA';

const EXPIRATION_SELLOUT = 'vencimiento';
const SPECIAL_SALE_SELLOUT = 'venta calzada';
const LOCAL_SPECIAL_SELLOUT = 'liquidacion';

const BILLER_AXTEROID = 'AXTEROID';
const BILLER_BSALE = 'BSALE';

const AXTEROID_DOC_TYPE_BOLETA = 'CL39';
const AXTEROID_DOC_TYPE_FACTURA = 'CL33';
const AXTEROID_DOC_TYPE_GUIA_DE_DESPACHO = 'CL52';
const AXTEROID_DOC_TYPE_NOTA_DE_CREDITO = 'CL61';
const AXTEROID_DOC_TYPE_FACTURA_DE_COMPRA = 'CL46';
const AXTEROID_DOC_TYPE_FACTURA_EXENTA = 'CL34';
const AXTEROID_DOC_TYPE_BOLETA_EXENTA = 'CL41';

const RECOMMENDED_PRICE_LIST_STORE = 91;

module.exports = {
  BUY_TYPE_SOCIO,
  BUY_TYPE_NO_SOCIO,
  BUY_TYPE_RAPPI,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_RAPPI_PAYLESS,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_AUX_DELIVERY,
  BUY_TYPE_GOODMEAL,
  COUPON_TYPE_DIFF,
  COUPON_TYPE_PERCENTAGE,
  PAYMENT_TYPE_METHOD,
  PAYMENT_TYPE_RAPPI,
  PAYMENT_TYPE_RAPPI_TURBO,
  PAYMENT_TYPE_RAPPI_PAYLESS,
  PAYMENT_TYPE_CORNERSHOP,
  PAYMENT_TYPE_UBEREATS,
  PAYMENT_TYPE_PEDIDOSYA,
  PAYMENT_TYPE_EFECTIVO,
  PAYMENT_TYPE_TARJETA_DEBITO,
  PAYMENT_TYPE_TARJETA_CREDITO,
  PAYMENT_TYPE_TRANSFERENCIA,
  PAYMENT_TYPE_WALLET,
  PAYMENT_TYPE_MERCADO_PAGO,
  PAYMENT_TYPE_MACH,
  PAYMENT_TYPE_WEBPAY,
  PAYMENT_TYPE_WEBPAY_CREDITO,
  PAYMENT_TYPE_WEBPAY_DEBITO,
  PYAMENT_TYPE_WEBPAY_PREPAGO,
  PAYMENT_TYPE_OTRO,
  PAYMENT_TYPE_NC,
  PAYMENT_TYPE_GOODMEAL,
  PAYMENT_TYPE_FREE,
  PAYMENT_TYPE_FPAY,
  PAYMENT_TYPE_CENCOSUD,
  PAYMENT_TYPE_CHEK,
  PAYMENT_TYPE_REDPAY,
  PAYMENT_TYPE_MARKETPLACE_WALMART,
  PAYMENT_TYPE_WEBPAY_ONECLICK_CREDITO,
  PAYMENT_TYPE_WEBPAY_ONECLICK_DEBITO,
  PAYMENT_TYPE_WEBPAY_ONECLICK_PREPAGO,
  SETTINGS_KEY_MIN_BULK_PRICE,
  STATUS_PENDIENTE,
  STATUS_APROBADO,
  STATUS_RECHAZADO,
  STATUS_CANCELADO,
  STATUS_GUARDADO,
  STATUS_ERROR,
  STATUS_REVISADO,
  STATUS_PAGANDO,
  STATUS_PENDING,
  STATUS_ACEPTADO,
  STATUS_ANULADO,
  STATUS_FALLA_CANCELACIÓN,
  STATUS_VERIFICANDO,
  STATUS_BSALE_ERROR,
  STATUS_COMPLETADO,
  STATUS_REVISADO_MANUAL,
  STATUS_BILLING_PROCESSING,
  STATUS_FINISHED,
  STATUS_INGRESADO,
  DELIVERY_STATUS_PENDIENTE,
  DELIVERY_STATUS_EN_PROCESO,
  DELIVERY_STATUS_PREPARADO,
  DELIVERY_STATUS_POR_PREPARAR,
  DELIVERY_STATUS_POR_ENTREGAR,
  DELIVERY_STATUS_EN_RUTA,
  DELIVERY_STATUS_ENTREGADO,
  DELIVERY_STATUS_NO_ENTREGADO,
  DELIVERY_TIME_PROGRAMMED,
  DELIVERY_TIME_IMMEDIATE,
  DELIVERY_TYPE_DELIVERY,
  DELIVERY_TYPE_PICKUP,
  DELIVERY_PROGRAMMED_TYPE_SAME_DAY,
  DELIVERY_PROGRAMMED_TYPE_SAME_DAY_TODAY,
  DELIVERY_PROGRAMMED_TYPE_SAME_DAY_TOMORROW,
  DELIVERY_PROGRAMMED_TYPE_NEXT_DAY,
  DELIVERY_PROGRAMMED_TYPE_IN_DAYS,
  DELIVERY_PROGRAMMED_TYPE_CUSTOM_DAY,
  DELIVERY_PROGRAMMED_TYPE_THREE_DAYS,
  DELIVERY_PROGRAMMED_TYPE_FIVE_DAYS,
  DELIVERY_PROGRAMMED_TYPE_SEVEN_DAYS,
  DELIVERY_PROGRAMMED_TYPE_TEN_DAYS,
  INTERNET_STORE_ID,
  CD_STORE_ID,
  ENTERED_PURCHASE_ORDER,
  GENERATED_PURCHASE_ORDER,
  CANCELLED_PURCHASE_ORDER,
  FAILED_PURCHASE_ORDER,
  PROCESSING_PURCHASE_ORDER,
  TERMINATED_PURCHASE_ORDER,
  RE_OPEN_PURCHASE_ORDER,
  INITIATED_PURCHASE_INVOICE,
  FINISHED_PURCHASE_INVOICE,
  ENTERED_STORE_ORDER,
  GENERATED_STORE_ORDER,
  CANCELLED_STORE_ORDER,
  RECEIVED_STORE_ORDER,
  FAILED_STORE_ORDER,
  PROCESSING_STORE_ORDER,
  PREPARED_STORE_ORDER,
  TO_PREPARE_STORE_ORDER,
  RE_OPEN_STORE_ORDER,
  DOCUMENT_TYPE_BOLETA,
  DOCUMENT_TYPE_FACTURA,
  DOCUMENT_TYPE_NOTA_DE_CREDITO,
  DOCUMENT_TYPE_GUIA_DE_DESPACHO,
  CASH_MOVEMENT_TYPE_INCOME,
  CASH_MOVEMENT_TYPE_OUTCOME,
  TZ_AMERICA_SANTIAGO,
  PROCESSING,
  CASA_MATRIZ_STORE_ID,
  CHACABUCO_STORE_ID,
  BILBAO_STORE_ID,
  FRANCHISE_STORE_IDS,
  SAN_PABLO_STORE_ID,
  CHIGUAYANTE_2_STORE_ID,
  LA_DEHESA_TURBO_RAPPI_STORE_ID,
  MERCADO_LIBRE_STORE_ID,
  MOVEMENT_REASON,
  NORMAL_SUPPLY_TYPE,
  CENTRALIZED_SUPPLY_TYPE,
  DIRECT_SUPPLY_TYPE,
  OTHER_SUPPLY_TYPE,
  FORCE_CENTRALIZED_SUPPLY_TYPE,
  FORCE_DIRECT_SUPPLY_TYPE,
  CENCOSUD_STATUS_UNABLE_TO_FULFILL,
  PROVIDER_TRANSBANK,
  PROVIDER_TRANSBANK_SMARTPOS,
  PROVIDER_REDELCOM,
  PROVIDER_ECOMMERCE,
  DOC_TYPES,
  ROLE_ADMIN,
  ROLE_VENDOR,
  ROLE_EDITOR,
  ROLE_ADMIN_FRANCHISE,
  ROLE_VENDOR_FRANCHISE,
  EXPIRATION_SELLOUT,
  SPECIAL_SALE_SELLOUT,
  LOCAL_SPECIAL_SELLOUT,
  BILLER_AXTEROID,
  BILLER_BSALE,
  AXTEROID_DOC_TYPE_BOLETA,
  AXTEROID_DOC_TYPE_FACTURA,
  AXTEROID_DOC_TYPE_GUIA_DE_DESPACHO,
  AXTEROID_DOC_TYPE_NOTA_DE_CREDITO,
  AXTEROID_DOC_TYPE_FACTURA_DE_COMPRA,
  AXTEROID_DOC_TYPE_FACTURA_EXENTA,
  AXTEROID_DOC_TYPE_BOLETA_EXENTA,
  RECOMMENDED_PRICE_LIST_STORE,
  FRANCHISE_TYPE,
  INVOICE_TYPE,
  FRANCHISE_PROD_TYPE_CD,
  FRANCHISE_PROD_TYPE_DIRECT,
  INVOICE_TYPE_FRANCHISE,
  INVOICE_TYPE_GENERAL,
  FRANCHISE_FEE_TYPE_DIRECT_DESCRIPTION,
  FRANCHISE_FEE_TYPE_CD_DESCRIPTION,
};
