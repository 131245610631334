/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  RESET_CACHE,
  UPDATE_CACHE
} from 'src/actions/searchCacheActions';

const initialState = {
  cache: null
};

const searchCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CACHE: {
      return produce(state, (draft) => {
        draft.cache = null;
      });
    }

    case UPDATE_CACHE: {
      const { cache } = action.payload;

      return produce(state, (draft) => {
        draft.cache = cache;
      });
    }

    default: {
      return state;
    }
  }
};

export default searchCacheReducer;
