// /* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  errorboundaryPage: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: 'black',
    margin: '0 auto',
  },
  errorboundaryLogo: {
    width: '300px',
    height: '66px',
  },
  errorboundaryTitle: {
    padding: '10px',
    margin: '10px',
    width: '100%',
    maxWidth: '700px',
    textAlign: 'center',
    fontFamily: 'Arial',
  },
  errorboundaryError: {
    fontFamily: 'Arial',
    margin: '20px',
  },
  errorboundaryButton: {
    fontSize: '20px',
    margin: '20px',
    padding: '20px',
    backgroundColor: '#e6d51b',
    borderRadius: '10px',
    fontWeight: '600',
    cursor: 'pointer',
  },
};

class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = { hasError: '' };

    this.handleOneClickButton = function resetData() {
      // Clear all local storage
      localStorage.clear();

      // Expire all site cookies
      const cookies = document.cookie.split(';');
      cookies.forEach((cookie) => {
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
      });

      // Redirect to login
      window.location.assign('/');
    };
  }

  componentDidCatch(error /* , errorInfo */) {
    this.setState({ hasError: error.message });
    // TODO: send an email with the error
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return (
        <div style={styles.errorboundaryPage}>
          <img
            style={styles.errorboundaryLogo}
            src="https://storage.googleapis.com/liquidos-public/logo-liquidos-blanco.png"
            alt="logo"
          />
          <h1 style={styles.errorboundaryTitle}>Ha ocurrido un error</h1>
          <h3 style={styles.errorboundaryError}>{state.hasError}</h3>
          <button
            type="button"
            style={styles.errorboundaryButton}
            onClick={this.handleOneClickButton}
          >
            Refrescar
          </button>
        </div>
      );
    }

    return props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
